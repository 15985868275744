import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"

const SecondPage = () => (
  <Layout>
    <SEO title="About Us" />
    <h1>About Us</h1>
    <p>CodeSpot is a blog focus on innovation in technology, education, creativity, and design. CodeSpot inspires readers to learn and expand their knowledge. </p>
    <p>Launched in September 2019 by Aleksandar Vasilevski, CodeSpot was founded to help new Software Developers to gain knowledge in the field of Software and Web Development also to improve the knowledge of experienced Software Developers, in the field of programming and the way of thinking.</p>
    <p>Contact us at code.spot.2019@gmail.com</p>
    <p>Click here to read our <Link to="/privacy-policy/">privacy policy</Link></p>
  </Layout>
)

export default SecondPage
